import React, { Suspense, useEffect, useState, lazy } from "react";

// Libraries
import {Routes, Route, useLocation, Link} from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./Context/Context";

// Components
import ScrollToTopButton from "./Components/ScrollToTop"
import {Footer} from "./Components/Footers/Footer";
import {Col, Container, Row} from "react-bootstrap";

// Pages
const HomePage = lazy(() => import("./Pages/HomePage"))



function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false
  })
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener('load', retina(document.querySelectorAll('img')));
  }, [])

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then(module => {
        module.SetHeaderMenuPos()
        module.setDocumentFullHeight()
      })
    }, 1000);
  }, [location])

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            <ScrollToTopButton />
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={<></>}>
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="*" element={<HomePage/>} />
                </Routes>
              </Suspense>
            </AnimatePresence>
          </main>
        }
      </div>
      {/* Footer Start */}
      <Footer theme="dark" className="text-slateblue bg-[#262b35] footer-style-03">
        <div className="py-[35px] border-t border-[#ffffff1a]">
          <Container>
            <Row>
              <Col md={3} className="sm:mb-[20px]">
                <Link aria-label="link" to="/" className="sm:flex sm:justify-center">
                  <img src="/assets/img/logo.svg" alt="logo" width="111" height="36" />
                </Link>
              </Col>
              <Col md={6} className="flex justify-center sm:mb-[20px]">
                <p className="mb-0 xs:text-center">&copy; {new Date().getFullYear()} INITIVO is Proudly Powered by <a aria-label="dercodo.io" rel="noreferrer" className="text-themecolor font-sans underline underline-offset-4 font-normal text-base" rel="noreferrer" target="_blank" href="https://dercode.io">DerCode</a></p>
              </Col>
              <Col md={3}>
                {/*<SocialIcons size="xs" theme="social-icon-style-01" className="felx justify-end md:justify-center" iconColor={props.theme === "dark" ? "light" : "dark"} data={iconData} />*/}
              </Col>
            </Row>
          </Container>
        </div>
      </Footer>
      {/* Footer End */}
    </GlobalContext.Provider>
  )
}

export default App;